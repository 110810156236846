import { Component, JSX } from "solid-js";
import Heading from "./Heading";
import Button, { ButtonStyle } from "./Button";
import ButtonGroup from "./ButtonGroup";
import { saveBlobUrl } from "@/utils";
import Player from "./Player";
import { GenerationResult } from "@/lib/GenerationResult";

const GenerateComplete: Component<{ generationResult: GenerationResult, onBack: () => void }> = (props): JSX.Element => {
  gtag("event", "generate_complete");

  const handleDownload = () => {
    gtag("event", "generate_download");
    saveBlobUrl(props.generationResult.url, props.generationResult.filename)
  };

  return (
    <>
      <Heading>Your podcast is ready!</Heading>
      
      <p>
        You can listen to it now using the player below or download it to enjoy on any device at your convenience.
      </p>

      <Player generationResult={props.generationResult} />
      
      <ButtonGroup>
        <Button text="Back" style={ButtonStyle.Secondary} onClick={props.onBack}></Button>
        <Button text="Download" onClick={handleDownload}></Button>
      </ButtonGroup>
    </>
  );
};

export default GenerateComplete;