import { type JSX, type Component, createSignal, createMemo } from "solid-js";

import Button from "./Button";
import Expander from "./Expander";
import IntroMessage from "./IntroMessage";
import UserSummary from "./UserSummary";
import Box from "./Box";
import { DigestConfig, DigestSettings, makeConfig, OutputType, ReviewSeparator, ReviewStyle } from "@/services/Generator";
import ButtonGroup from "./ButtonGroup";
import { useSummary } from "@/contexts/WaniKani";
import SettingsForm from "./SettingsForm";
import AudioSettingsForm from "./AudioSettingsForm";
import { makePersisted } from "@solid-primitives/storage";

export type SettingsUpdaterFn = (fn: SettingsUpdater) => void;
export type SettingsUpdater = (settings: DigestSettings) => void;

const defaultSettings = (): DigestSettings => {
  return {
    reviewStyle: ReviewStyle.JapaneseFirst,
    pauseBeforeAnswerDuration: 5,
    reviewSeparator: ReviewSeparator.ReviewNumber,
    audioFormat: {
      type: OutputType.Wave
    }
  };
};

// if changing settings structure, consider updating this and some how migrating new defaults in to existing settings
const SETTINGS_VERSION = 1;

const GenerateForm: Component<{ setConfig: (config: DigestConfig) => void}> = (props): JSX.Element => {
  const summary = useSummary();

  const settingsOpen = createSignal<boolean>(false);
  const audioSettingsOpen = createSignal<boolean>(false);

  const [settings, setSettings] = makePersisted(createSignal(defaultSettings()), { storage: localStorage, name: `generation_settings:${SETTINGS_VERSION}` });

  const formDisabled = createMemo(() => !settings() || !summary());

  const updateSettings = (fn: SettingsUpdater) => {
    const newSettings = structuredClone(settings()!);
    fn(newSettings);
    setSettings(newSettings);
  };

  const handleGenerate = async () => {
    gtag("event", "generate_start");
    props.setConfig(makeConfig(summary()!, settings()!));
  };

  return (
    <Box>
      <UserSummary />

      <IntroMessage />
      
      <Expander title="Settings" open={settingsOpen} disabled={formDisabled()}>
        {settings() && (
          <SettingsForm settings={settings()!} updateSettings={updateSettings} />
        )}
      </Expander>

      <Expander title="Audio settings" open={audioSettingsOpen} disabled={formDisabled()}>
        {settings() && (
          <AudioSettingsForm settings={settings()!} updateSettings={updateSettings} />
        )}
      </Expander>

      <ButtonGroup>
        <Button text="Generate" onClick={handleGenerate} disabled={formDisabled()} />
      </ButtonGroup>
    </Box>
  )
};

export default GenerateForm;