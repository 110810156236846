export type GenerationResult = {
  url: string,
  filename: string,
  segments: SegmentInfo[],
}

export type SegmentInfo = {
  tags: AudioTag[],
  startTime: number,
  endTime: number,
};

export enum AudioTag {
  Intro = "intro",
  Pause = "pause",
  ReviewSeparator = "review_separator",
  ReviewStart = "review_start",
  ReviewEnd = "review_end",
  English = "english",
  Japanese = "japanese",
  Question = "question",
  Answer = "answer",
  Outro = "outro"
}
