import forEachRight from "lodash-es/forEachRight";
import { AudioTag, SegmentInfo } from "./GenerationResult";

export type PlayerState = {
  currentIndex: number,

  currentReviewStartIndex?: number,
  previousReviewStartIndex?: number,
  nextReviewStartIndex?: number,
}

type OptimisedSegmentInfo = SegmentInfo & {
  currentReviewStartIndex?: number
  previousReviewStartIndex?: number
  nextReviewStartIndex?: number
};

export function buildOptimisedSegments(segments: SegmentInfo[]): OptimisedSegmentInfo[] {
  const results: OptimisedSegmentInfo[] = [];

  let previousReviewStartIndex: number | undefined;
  let currentReviewStartIndex: number | undefined;

  segments.forEach((s, index) => {
    if (s.tags.includes(AudioTag.ReviewStart)) {
      currentReviewStartIndex = index;
    }

    results.push({
      ...s,
      currentReviewStartIndex,
      previousReviewStartIndex
    })

    if (s.tags.includes(AudioTag.ReviewEnd)) {
      previousReviewStartIndex = currentReviewStartIndex;
      currentReviewStartIndex = undefined;
    }
  });

  let nextReviewStartIndex: number | undefined;

  forEachRight(results, (s, index) => {
    s.nextReviewStartIndex = nextReviewStartIndex;
    if (s.tags.includes(AudioTag.ReviewStart)) {
      nextReviewStartIndex = index
    }
  });

  return results;
} 

export function playerStateAt(time: number, segments: OptimisedSegmentInfo[]): PlayerState {
  const currentIndex = segments.findIndex((s, idx) => {
    if (idx === 0 && time < s.startTime) {
      return true;
    }

    if (idx === segments.length -1 && time >= s.endTime) {
      return true;
    }  

    return time >= s.startTime && time < s.endTime
  });

  const current = segments[currentIndex];

  return {
    currentIndex,
    currentReviewStartIndex: current.currentReviewStartIndex,
    previousReviewStartIndex: current.previousReviewStartIndex,
    nextReviewStartIndex: current.nextReviewStartIndex
  }
}