import { Component, JSX } from "solid-js";
import Spinner from "./Spinner";

import styles from "./Button.module.scss";
import clsx from "clsx";

export enum ButtonStyle {
  Default = "default",
  Secondary = "secondary"
};

const Button: Component<{ text: string, style?: ButtonStyle, loading?: boolean, disabled?: boolean, onClick?: () => void, form?: string }> = (props): JSX.Element => {
  return (
    <button class={clsx(styles.button, styles[props.style || ButtonStyle.Default])} onClick={props.onClick} disabled={props.loading || props.disabled} form={props.form}>
      {props.loading && <Spinner />} 
      {!props.loading && props.text}
    </button>
  );
};

export default Button;