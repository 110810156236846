import { Accessor, Component, JSX, ParentProps } from "solid-js";
import { GenerationState } from "../services/Generator";
import Card from "./Card";
import Heading from "./Heading";

import styles from "./GenerateProgress.module.scss";
import Pill, { PillStyle } from "./Pill";
import ButtonGroup from "./ButtonGroup";
import Button, { ButtonStyle } from "./Button";

const StatusItem: Component<ParentProps<{ title: string, started: boolean, completed: boolean }>> = (props): JSX.Element => {
  return (
    <div class={styles.statusItem}>
      <strong>{props.title}</strong>

      {!props.started && (
        <Pill style={PillStyle.Default}>Waiting...</Pill>
      )}

      {props.started && !props.completed && (
        <Pill style={PillStyle.Info}><strong>In progress</strong> <span>{props.children}</span></Pill>
      )}

      {props.completed && (
        <Pill style={PillStyle.Success}><strong>Complete</strong> <span>{props.children}</span></Pill>
      )}
    </div>
  );
}

const GenerateProgress: Component<{ state: Accessor<GenerationState>, onCancel: () => void }> = (props): JSX.Element => {
  return (
    <>
      <Heading>Building podcast</Heading>

      <p>
        Sit tight! We're creating your personalized Japanese learning podcast. This won't take long &mdash; soon, you'll have everything ready to listen and learn at your convenience!
      </p>

      <Card>
        <StatusItem title="Synthesising speech" {...props.state().downloads}>
          {props.state().downloads.stats.complete}/{props.state().downloads.stats.total}
        </StatusItem>
        <StatusItem title="Building reviews" {...props.state().reviews}>
          {props.state().reviews.stats.complete}/{props.state().reviews.stats.total}
        </StatusItem>
        {!props.state().encoding.skipped && (
          <StatusItem title="Encoding audio" {...props.state().encoding}>
            {Math.round(props.state().encoding.stats.progress)}%
          </StatusItem>
        )}
      </Card>

      <ButtonGroup>
        <Button text="Cancel" style={ButtonStyle.Secondary} onClick={props.onCancel}></Button>
      </ButtonGroup>
    </>
  );
};

export default GenerateProgress;