import { Component, createEffect, createMemo, createSignal, JSX } from "solid-js";

import styles from "./Player.module.scss";
import { createAudio } from "@solid-primitives/audio";
import { Duration } from "luxon";
import {  GenerationResult} from "@/lib/GenerationResult";
import { buildOptimisedSegments, playerStateAt } from "@/lib/PlayerState";


const VolumeIcon: Component<{ volume: number }> = (props): JSX.Element => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {props.volume === 0 && (<path d="M22.5314 13.4197L21.0814 11.9697L22.4814 10.5697C22.7714 10.2797 22.7714 9.79969 22.4814 9.50969C22.1914 9.21969 21.7114 9.21969 21.4214 9.50969L20.0214 10.9097L18.5714 9.45969C18.2814 9.16969 17.8014 9.16969 17.5114 9.45969C17.2214 9.74969 17.2214 10.2297 17.5114 10.5197L18.9614 11.9697L17.4714 13.4597C17.1814 13.7497 17.1814 14.2297 17.4714 14.5197C17.6214 14.6697 17.8114 14.7397 18.0014 14.7397C18.1914 14.7397 18.3814 14.6697 18.5314 14.5197L20.0214 13.0297L21.4714 14.4797C21.6214 14.6297 21.8114 14.6997 22.0014 14.6997C22.1914 14.6997 22.3814 14.6297 22.5314 14.4797C22.8214 14.1897 22.8214 13.7197 22.5314 13.4197Z" />)}
      {props.volume > 0.25 && (<path d="M18.0003 16.7503C17.8403 16.7503 17.6903 16.7003 17.5503 16.6003C17.2203 16.3503 17.1503 15.8803 17.4003 15.5503C18.9703 13.4603 18.9703 10.5403 17.4003 8.45027C17.1503 8.12027 17.2203 7.65027 17.5503 7.40027C17.8803 7.15027 18.3503 7.22027 18.6003 7.55027C20.5603 10.1703 20.5603 13.8303 18.6003 16.4503C18.4503 16.6503 18.2303 16.7503 18.0003 16.7503Z" />)}
      {props.volume > 0.75 && (<path d="M19.8284 19.2503C19.6684 19.2503 19.5184 19.2003 19.3784 19.1003C19.0484 18.8503 18.9784 18.3803 19.2284 18.0503C21.8984 14.4903 21.8984 9.51027 19.2284 5.95027C18.9784 5.62027 19.0484 5.15027 19.3784 4.90027C19.7084 4.65027 20.1784 4.72027 20.4284 5.05027C23.4984 9.14027 23.4984 14.8603 20.4284 18.9503C20.2884 19.1503 20.0584 19.2503 19.8284 19.2503Z" />)}
      <path d="M14.02 3.78168C12.9 3.16168 11.47 3.32168 10.01 4.23168L7.09 6.06168C6.89 6.18168 6.66 6.25168 6.43 6.25168H5.5H5C2.58 6.25168 1.25 7.58168 1.25 10.0017V14.0017C1.25 16.4217 2.58 17.7517 5 17.7517H5.5H6.43C6.66 17.7517 6.89 17.8217 7.09 17.9417L10.01 19.7717C10.89 20.3217 11.75 20.5917 12.55 20.5917C13.07 20.5917 13.57 20.4717 14.02 20.2217C15.13 19.6017 15.75 18.3117 15.75 16.5917V7.41168C15.75 5.69168 15.13 4.40168 14.02 3.78168Z" />
    </svg>
  );
};

const Play: Component = (): JSX.Element => {
  return (
    <svg viewBox="-1 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g transform="translate(-227.000000, -3765.000000)">
          <g transform="translate(56.000000, 160.000000)">
            <path d="M172,3605 C171.448,3605 171,3605.448 171,3606 L171,3612 C171,3612.552 171.448,3613 172,3613 C172.552,3613 173,3612.552 173,3612 L173,3606 C173,3605.448 172.552,3605 172,3605 M177,3606 L177,3612 C177,3612.552 176.552,3613 176,3613 C175.448,3613 175,3612.552 175,3612 L175,3606 C175,3605.448 175.448,3605 176,3605 C176.552,3605 177,3605.448 177,3606" />
          </g>
        </g>
      </g>
    </svg>
  );
};

const Pause: Component = (): JSX.Element => {
  return (
    <svg viewBox="-1 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g transform="translate(-65.000000, -3803.000000)">
          <g transform="translate(56.000000, 160.000000)">
            <path d="M18.074,3650.7335 L12.308,3654.6315 C10.903,3655.5815 9,3654.5835 9,3652.8985 L9,3645.1015 C9,3643.4155 10.903,3642.4185 12.308,3643.3685 L18.074,3647.2665 C19.306,3648.0995 19.306,3649.9005 18.074,3650.7335" />
          </g>
        </g>
      </g>
    </svg>
  );
};

const Previous: Component = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 -2 8 8">
      <path d="M7 .86v-1.991c0-.714-.573-1.122-1.21-.694L1.48 1.307c-.515.347-.682 1.04-.168 1.387l4.478 3.13c.636.43 1.21.022 1.21-.692V3.14" />
    </svg>
  );
};

const Next: Component = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 -2 8 8">
      <path d="M1 .86v-1.991c0-.714.573-1.122 1.21-.694l4.31 3.132c.515.347.682 1.04.168 1.387L2.21 5.824c-.636.43-1.21.022-1.21-.692V3.14" />
    </svg>
  );
};

const Start: Component = (): JSX.Element => {
  return (
    <svg viewBox="0 -2 12 12" xmlns="http://www.w3.org/2000/svg" >
        <g>
            <g transform="translate(-104.000000, -3805.000000)">
                <g transform="translate(56.000000, 160.000000)">
                  <path d="M59.9990013,3645.86816 L59.9990013,3652.13116 C59.9990013,3652.84516 58.8540013,3653.25316 58.2180013,3652.82516 L53.9990013,3650.14016 L53.9990013,3652.13116 C53.9990013,3652.84516 53.4260013,3653.25316 52.7900013,3652.82516 L48.4790013,3649.69316 C47.9650013,3649.34616 47.7980013,3648.65316 48.3120013,3648.30616 L52.7900013,3645.17516 C53.4260013,3644.74616 53.9990013,3645.15416 53.9990013,3645.86816 L53.9990013,3647.85916 L58.2180013,3645.17516 C58.8540013,3644.74616 59.9990013,3645.15416 59.9990013,3645.86816" />
                </g>
            </g>
        </g>
    </svg>
  );
};

// const End: Component = (): JSX.Element => {
//   return (
//     <svg viewBox="0 -2 12 12" xmlns="http://www.w3.org/2000/svg">
//       <g>
//         <g transform="translate(-144.000000, -3805.000000)">
//           <g transform="translate(56.000000, 160.000000)">
//             <path d="M99.684,3649.69353 L95.207,3652.82453 C94.571,3653.25353 94,3652.84553 94,3652.13153 L94,3650.14053 L89.78,3652.82453 C89.145,3653.25353 88,3652.84553 88,3652.13153 L88,3645.86853 C88,3645.15453 89.145,3644.74653 89.78,3645.17453 L94,3647.85953 L94,3645.86853 C94,3645.15453 94.571,3644.74653 95.207,3645.17453 L99.516,3648.30653 C100.03,3648.65353 100.198,3649.34653 99.684,3649.69353" />
//           </g>
//         </g>
//       </g>
//     </svg>
//   );
// };

const Player: Component<{ generationResult: GenerationResult }> = (props): JSX.Element => {
  const [playing, setPlaying] = createSignal(false);
  const [currentTime, setCurrentTime] = createSignal(0.0);
  const [rememberedVolume, setRememberedVolume] = createSignal(1.0);
  const [volume, setVolume] = createSignal(1.0);
  const [audio, { seek }] = createAudio(props.generationResult.url, playing, volume);

  const optimisedSegments = createMemo(() => buildOptimisedSegments(props.generationResult.segments));
  const playerState = createMemo(() => playerStateAt(currentTime(), optimisedSegments()!))

  createEffect(() => {
    setCurrentTime(audio.currentTime);
  });

  const onVolumeClick = () => {
    if (volume() > 0.0) {
      gtag("event", "player_mute");
      setRememberedVolume(volume());
      setVolume(0.0);
    } else {
      gtag("event", "player_unmute");
      setVolume(rememberedVolume());
    }
  };

  const handleVolumeChange: JSX.EventHandler<HTMLInputElement, InputEvent> = (e) => {
    gtag("event", "player_volume_change");
    setVolume(parseFloat(e.currentTarget.value));
  }

  const handlePrevReview = () => {
    const previousReviewStartIndex = playerState()?.previousReviewStartIndex;

    if (previousReviewStartIndex) {
      gtag("event", "player_go_prev_review");
      const segment = props.generationResult.segments[previousReviewStartIndex];
      setCurrentTime(segment.startTime);
      seek(segment.startTime);
    }
  };

  const handleNextReview = () => {
    const nextReviewStartIndex = playerState()?.nextReviewStartIndex;

    if (nextReviewStartIndex) {
      gtag("event", "player_go_next_review");
      const segment = props.generationResult.segments[nextReviewStartIndex];
      setCurrentTime(segment.startTime);
      seek(segment.startTime);
    }
  };

  const handleStart = () => {
    gtag("event", "player_go_start");
    setCurrentTime(0);
    seek(0);
  }

  const handlePlayPause = () => {
    gtag("event", "player_play_pause");
    setPlaying((v) => !v);
  }

  return (
    <div class={styles.player}>
      <button title="Play/Pause" class={styles.button} onClick={handlePlayPause}>
        {playing() && <Play />}
        {!playing() && <Pause />}
      </button>

      <button title="Start" class={styles.button} onClick={handleStart}><Start /></button>

      <div class={styles.volume}>
        <button title="Mute/Unmute" onClick={onVolumeClick} class={styles.volumeButton}>
          <VolumeIcon volume={volume()} />
        </button>

        <input type="range" min={0.0} max={1.0} step={0.01} value={volume()} onInput={handleVolumeChange}  />
      </div>

      <div class={styles.progress}>
        <button title="Previous review" class={styles.button} onClick={handlePrevReview} disabled={!playing() || playerState()?.previousReviewStartIndex === undefined}><Previous /></button>

        <div class={styles.time}>
          {`${Duration.fromMillis(audio.currentTime * 1000.0).toFormat("mm:ss")} / ${Duration.fromMillis(audio.duration * 1000.0).toFormat("mm:ss")}`}
        </div>

        <button title="Next review" class={styles.button} onClick={handleNextReview} disabled={!playing() || playerState()?.nextReviewStartIndex === undefined}><Next /></button>
      </div>
    </div>
  );
};

export default Player;