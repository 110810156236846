import { type JSX, type Component, createSignal, onCleanup, createResource, Match, Switch } from "solid-js";
import { DigestConfig, generate, GenerationState, initialState } from "../services/Generator";
import Box from "./Box";

import GenerateComplete from "./GenerateComplete";
import GenerateProgress from "./GenerateProgress";
import { Fallback } from "./ErrorHandler";

const Generate: Component<{ config: DigestConfig, onBack: () => void }> = (props): JSX.Element => {
  const [state, setState] = createSignal<GenerationState>(initialState(props.config));
  const abortController = new AbortController();
  const [generationResult, { mutate }] = createResource(() => generate(props.config, setState, abortController));

  onCleanup(() => {
    if (generationResult()) {
      URL.revokeObjectURL(generationResult()!.url);
    }
  });

  const handleCancel = () => {
    gtag("event", "generate_cancel");
    abortController.abort();
    mutate(undefined);
    props.onBack();
  };

  const handleReturn = () => {
    gtag("event", "generate_back_to_config");
    props.onBack();
  }

  return (
    <Box>
      <Switch>
        <Match when={generationResult.error}>
          <Fallback />
        </Match>
        <Match when={generationResult.loading}>
          <GenerateProgress state={state} onCancel={handleCancel} />
        </Match>
        <Match when={generationResult()}>
          <GenerateComplete generationResult={generationResult()!} onBack={handleReturn} />
        </Match>
      </Switch>
    </Box>
  )
};

export default Generate;