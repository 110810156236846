import { Duration } from "luxon";
import { IndexedDbStore } from "../services/IndexedDbStore";
import qs from "qs";
import { sleep } from "../utils";
import difference from "lodash-es/difference";
import { SubjectType } from "./WaniKani";

export async function segment(params: any, signal?: AbortSignal): Promise<Blob> {
  const query = qs.stringify(params);

  const response = await fetch(
    `/api/segments?${query}`, 
    { 
      method: "GET",
      signal
    }
  );

  if (response.status === 409) {
    gtag("event", "segment_conflict");

    await sleep(1000);
    return segment(params, signal);
  }

  if (response.status === 429) {
    gtag("event", "segment_rate_limit");

    await sleep(2000);
    return segment(params, signal);
  }

  if (response.status === 200) {
    return await response.blob();
  }

  throw new Error(`Unexpected response ${response.status}`);
};

export async function subjectTypes(subjectIds: number[], storage: IndexedDbStore): Promise<Record<SubjectType, number[]>> {
  const cached = (await Promise.all(
    subjectIds.map(async (id) => { 
      const type = await storage.get(`subject_type:${id}`);
      return type ? { id, type } : null;
    })
  )).filter(x => x !== null);

  const cachedIds = cached.map(({id}) => id);
  const uncachedIds = difference(subjectIds, cachedIds);
  let fetched: { id: number, type: SubjectType }[] = [];
  if (uncachedIds.length > 0) {
    const response = await fetch(
      "/api/summary", 
      { 
        method: "POST",
        body: JSON.stringify({ ids: uncachedIds }),
        headers: {
          "Content-Type": "application/json",
        }
      }
    );

    const fetchedResults = (await response.json()).results as Record<SubjectType, number[]>;

    fetched = await Promise.all(Object.entries(fetchedResults).flatMap(([type, subjectIds]) => {
      return subjectIds.map(async (id) => {
        await storage.set(`subject_type:${id}`, type, Duration.fromObject({ days: 30 }))

        return { id, type: type as SubjectType };
      })
    }));
  }

  const result: Record<SubjectType, number[]> = {
    kana_vocabulary: [],
    kanji: [],
    radical: [],
    vocabulary: []
  };

  [...fetched, ...cached].forEach(({ id, type }) => {
    result[type as SubjectType].push(id);
  });

  return result;
};
