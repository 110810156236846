import { Component, createContext, JSX, ParentProps, useContext } from "solid-js";
import ShortUniqueId from "short-unique-id";

import styles from "./Field.module.scss";

const { randomUUID } = new ShortUniqueId();

const FieldContext = createContext<FieldInfo>();

export type FieldProps = {
  name: string,
};

export type FieldInfo = {
  id: string,
} & FieldProps;

interface FieldWithSubComponents {
  (props: ParentProps<FieldProps>): JSX.Element; 
  Label: Component<ParentProps>;
  Help: Component<ParentProps>;
  Group: Component<ParentProps>;
  Error: Component<ParentProps>;
}

const Label: Component<ParentProps> = (props): JSX.Element => {
  const { id } = useField();

  return (
    <label class={styles.label} for={id}>
      {props.children}
    </label>
  )
};

const Help: Component<ParentProps> = (props): JSX.Element => {
  return (
    <p class={styles.help}>
      {props.children}
    </p>
  )
};

const Error: Component<ParentProps> = (props): JSX.Element => {
  return (
    <span class={styles.error}>
      {props.children}
    </span>
  )
};

const Group: Component<ParentProps> = (props): JSX.Element => {
  const { id } = useField();

  return (
    <fieldset class={styles.group} id={id}>
      {props.children}
    </fieldset>
  )
};

const Field: FieldWithSubComponents = (props): JSX.Element => {
  const value: FieldInfo = {
    name: props.name,
    id: randomUUID(),
  };
  
  return (
    <div class={styles.field}>
      <FieldContext.Provider value={value}>
        {props.children}
      </FieldContext.Provider>
    </div>
  )
};
Field.Label = Label;
Field.Help = Help;
Field.Group = Group;
Field.Error = Error;

export const useField = () => useContext(FieldContext)!;

export default Field;
