import { Component, JSX } from "solid-js";

import styles from "./TextInput.module.scss";
import { useField } from "./Field";
import clsx from "clsx";

export type TextInputProps = Omit<JSX.InputHTMLAttributes<HTMLInputElement>, "type" | "id" | "name">;

const TextInput: Component<TextInputProps> = (props): JSX.Element => {
  const { name, id } = useField();

  return (
    <input {...props} class={clsx(styles.input, props.class)} type="text" id={id} name={name} />
  )
};

export default TextInput;
